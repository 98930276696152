import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStateValue } from './Components/AppContext/StateProvider';
import MainRouter from './Components/Router/MainRouter';
import GrommetWrapper from './Components/Template/GrommetWrapper/GrommetWrapper';
import './Components/Template/i18n/language';
const App = () => {
    const { i18n } = useTranslation();
    const [{ Language }] = useStateValue();
    useEffect(() => {
        i18n.changeLanguage(Language);
    }, [Language]);
    return (React.createElement(GrommetWrapper, null,
        React.createElement(MainRouter, null)));
};
export default App;
