export default {
    Title: 'Iniciar sesión en su cuenta',
    Email: {
        Title: 'Por favor informe su correo electrónico',
        Text: 'Correo electrónico',
        Invalid: 'Dirección de correo electrónico inválida',
        Placeholder: 'tu-correo-electronico@email.com',
    },
    Password: {
        Title: 'Por favor informe su contraseña',
        Text: 'Contraseña',
        Invalid: 'Mínimo de 6 caracteres e máximo de 15 caracteres',
    },
    KeepConnected: {
        Title: 'Permanecer conectada durante 30 días',
        Text: 'Mantener conectado',
    },
    Submit: {
        Text: 'Entrar',
        Title: 'Haga clic aquí para ingresar',
    },
    Feedback: {
        Success: 'Inicio de sesión satisfactorio',
        Failure: 'Lo siento, pero hubo un error. Quizás credenciales incorrectas',
    },
    Forgot: {
        Text: '¿Olvidaste tu contraseña?',
        Title: 'Haga clic aquí para recibir una nueva contraseña en su correo electrónico',
    },
};
