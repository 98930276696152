const ChangeProfileSpa = {
    Title: 'Cambia tu perfil',
    NickName: {
        Text: 'Apodo de usuario',
        Title: 'Por favor, informe su apodo de usuario',
        Invalid: 'Los apodos son un identificador único',
    },
    Profile: {
        Text: 'Perfil',
        Title: 'Por favor complete su perfil',
    },
    Submit: {
        Text: 'Salvar',
        Title: 'Haga clic aquí para guardar los datos',
    },
    Feedback: {
        Success: 'Datos enviados con éxito',
        Failure: '¡UPS! Algo sucedió en la presentación. Por favor, revise los datos y envíelos nuevamente.',
    },
};
export default ChangeProfileSpa;
