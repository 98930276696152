export default {
    DropButton: {
        Manage: {
            Text: 'Administrar cuenta',
            Title: 'Haga clic aquí para administrar su cuenta',
        },
        Logout: {
            Text: 'Logout',
            Title: 'Haga clic aquí para cerrar su conexión',
        },
    },
    Login: {
        Title: 'Haga clic aquí para ingresar a su cuenta o crear una nueva',
    },
    Manage: {
        Title: 'Haga clic aquí para administrar su cuenta',
    },
};
