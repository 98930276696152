import i18next from 'i18next';
import { useEffect } from 'react';
import LanguageSPA from './LanguageSPA';
import LanguageENG from './LanguageENG';
export default () => {
    i18next.addResourceBundle('SPA', 'CookiesConsent', LanguageSPA);
    i18next.addResourceBundle('ENG', 'CookiesConsent', LanguageENG);
    const addEffect = () => {
        if (!i18next.hasResourceBundle('SPA', 'CookiesConsent')) {
            i18next.addResourceBundle('SPA', 'CookiesConsent', LanguageSPA);
        }
        if (!i18next.hasResourceBundle('ENG', 'CookiesConsent')) {
            i18next.addResourceBundle('ENG', 'CookiesConsent', LanguageENG);
        }
    };
    const cleanEffect = () => {
        i18next.removeResourceBundle('SPA', 'CookiesConsent');
        i18next.removeResourceBundle('ENG', 'CookiesConsent');
    };
    useEffect(() => {
        addEffect();
        return cleanEffect();
    }, []);
};
