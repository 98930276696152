const ChangeEmailSpa = {
    Title: 'Cambia tu email',
    Email: {
        Text: 'Email',
        Title: 'Por favor, informe su email',
        Invalid: 'Debe ser una dirección de correo electrónico válida',
    },
    Password: {
        Text: 'Confirmar con contraseña',
        Title: 'Usa tu contraseña para confirmar',
        Invalid: 'Mínimo 6 caracteres e máximo de 15 caracteres',
    },
    Submit: {
        Text: 'Salvar',
        Title: 'Haga clic aquí para guardar los datos',
    },
    Feedback: {
        Success: 'Datos enviados con éxito',
        Failure: '¡UPS! Algo sucedió en la presentación. Por favor, revise los datos y envíelos nuevamente. Quizás credenciales incorrectas.',
    },
};
export default ChangeEmailSpa;
