const ChangePicturePTBR = {
    Title: 'Alterar a imagem',
    Submit: {
        Text: 'Salvar',
        Title: 'Clique aqui para salvar',
    },
    Feedback: {
        Success: 'Imagem atualizado com sucesso',
        Failure: 'Desculpe, mas houve um problema ao tentar atualizar tua imagem',
    },
};
export default ChangePicturePTBR;
