const ChangeEmailEng = {
    Title: 'Change your email',
    Email: {
        Text: 'Email',
        Title: 'Please inform your email',
        Invalid: 'Must be a valid email address',
    },
    Password: {
        Text: 'Confirm with password',
        Title: 'Please confirm with your password',
        Invalid: 'Password must be 6-15 characters',
    },
    Submit: {
        Text: 'Save',
        Title: 'Click here to save your info',
    },
    Feedback: {
        Success: 'Email successfully updated',
        Failure: 'Sorry, but there was a problem updating your email. Maybe wrong credentials.',
    },
};
export default ChangeEmailEng;
