import React, { useState } from 'react';
import { Box, Button, FormField, TextInput, } from 'grommet';
import { Save } from 'grommet-icons';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStateValue } from '../../../AppContext/StateProvider';
import PageTitle from '../../../PageTitle/PageTitle';
import initialValues from './initialValues';
import FormWrapper from '../../../FormWrapper/FormWrapper';
import setChangePassword from './setChangePassword';
const ChangePassword = withTranslation()((props) => {
    const { t } = props;
    const [, dispatch] = useStateValue();
    const history = useHistory();
    const [values, setValues] = useState(initialValues());
    const [submitting, setSubmitting] = useState(false);
    const onChange = (value) => {
        setValues(value);
    };
    const submit = async (event) => {
        const { value } = event;
        setSubmitting(true);
        await setChangePassword(value)
            .then((data) => {
            if (data.Success) {
                toast.success(t('ManageAccount:ChangePassword.Feedback.Success'));
                dispatch({
                    type: 'changeAuth',
                    value: false,
                });
                dispatch({
                    type: 'changeDrawer',
                    value: false,
                });
                setSubmitting(false);
                history.push('/');
            }
            else {
                toast.error(t('ManageAccount:ChangePassword.Feedback.Failure'));
                setSubmitting(false);
            }
        })
            .catch(() => {
            toast.error(t('ManageAccount:ChangePassword.Feedback.Failure'));
            setSubmitting(false);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { text: t('ManageAccount:ChangePassword.Title'), level: "4", margin: {
                top: 'none',
                bottom: 'small',
            } }),
        React.createElement(FormWrapper, { onChange: onChange, values: values, onSubmit: submit },
            React.createElement(FormField, { label: t('ManageAccount:ChangePassword.NewPassword.Text'), width: "large", name: "NewPassword", required: true, validate: [
                    (fName) => {
                        if (fName.length < 2)
                            return t('ManageAccount:ChangePassword.NewPassword.Invalid');
                        return undefined;
                    },
                ] },
                React.createElement(TextInput, { name: "NewPassword", type: "password", placeholder: "******", "aria-label": t('ManageAccount:ChangePassword.NewPassword.Title'), title: t('ManageAccount:ChangePassword.NewPassword.Title') })),
            React.createElement(FormField, { label: t('ManageAccount:ChangePassword.ConfirmPassword.Text'), width: "large", name: "ConfirmPassword", required: true, validate: [
                    (lName) => {
                        if (lName.length < 2)
                            return t('ManageAccount:ChangePassword.ConfirmPassword.Invalid');
                        return undefined;
                    },
                ] },
                React.createElement(TextInput, { name: "ConfirmPassword", type: "password", placeholder: "******", "aria-label": t('ManageAccount:ChangePassword.ConfirmPassword.Title'), title: t('ManageAccount:ChangePassword.ConfirmPassword.Title') })),
            React.createElement(FormField, { label: t('ManageAccount:ChangePassword.Password.Text'), width: "large", name: "Password", required: true, validate: [
                    (Password) => {
                        if (Password.length < 6 || Password.length > 15)
                            return t('ManageAccount:ChangePassword.Password.Invalid');
                        return undefined;
                    },
                ] },
                React.createElement(TextInput, { name: "Password", type: "password", "aria-label": t('ManageAccount:ChangePassword.Password.Title'), title: t('ManageAccount:ChangePassword.Password.Title'), placeholder: "******" })),
            React.createElement(Box, { direction: "row", justify: "between", margin: { top: 'large' } },
                React.createElement(Button, { icon: React.createElement(Save, null), type: "submit", label: t('ManageAccount:ChangePassword.Submit:Text'), title: t('ManageAccount:ChangePassword.Submit:Title'), primary: true, reverse: true, disabled: submitting })))));
});
ChangePassword.displayName = 'ChangePassword';
export default ChangePassword;
