const ChangePasswordSpa = {
    Title: 'cambia tu contraseña',
    NewPassword: {
        Text: 'Nueva contraseña',
        Title: 'Por favor, informe su nueva contraseña',
        Invalid: 'Mínimo 6 caracteres e máximo de 15 caracteres',
    },
    ConfirmPassword: {
        Text: 'Confirmar Contraseña',
        Title: 'Confirme su nueva contraseña',
        Invalid: 'La nueva contraseña y Confirmar contraseña deben ser iguales',
    },
    Password: {
        Text: 'Confirmar con contraseña',
        Title: 'Usa tu contraseña para confirmar',
        Invalid: 'Mínimo 6 caracteres e máximo de 15 caracteres',
    },
    Submit: {
        Text: 'Salvar',
        Title: 'Haga clic aquí para guardar los datos',
    },
    Feedback: {
        Success: 'Datos enviados con éxito',
        Failure: '¡UPS! Algo sucedió en la presentación. Por favor, revise los datos y envíelos nuevamente. Quizás credenciales incorrectas.',
    },
};
export default ChangePasswordSpa;
