const ChangePictureEng = {
    Title: 'Change your picture',
    Submit: {
        Text: 'Save',
        Title: 'Click here to save your new picture',
    },
    Feedback: {
        Success: 'Picture successfully updated',
        Failure: 'Sorry, but there was a problem updating your picture',
    },
};
export default ChangePictureEng;
