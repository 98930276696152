import { Anchor, Box, Image, Paragraph, Stack, Text, } from 'grommet';
import React, { useRef, useState, } from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ImageEditorLayer from './ImageEditorLayer';
const ImageUploader = withTranslation()((props) => {
    const { t, image, onSaveResult, aspect, } = props;
    const input = useRef(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [imageBase64, setImageBase64] = useState('');
    const onDragClick = () => {
        if (input.current)
            input.current.click();
    };
    const validateFile = async (file) => {
        if (!file.type.startsWith('image/')) {
            setErrorMessage('File must be an image');
            setShowErrorMessage(true);
            return;
        }
        if (file.size > 512000) {
            setErrorMessage('File must be up to 500 bytes');
            setShowErrorMessage(true);
            return;
        }
        const reader = new FileReader();
        reader.onload = () => {
            const { result } = reader;
            const [extension] = file.name.split('.').reverse();
            if (!extension) {
                setErrorMessage('File must be up to 500 bytes');
                setShowErrorMessage(true);
                return;
            }
            // @ts-ignore: Object is possibly 'null'.
            setImageBase64(result.toString());
            setShowEditor(true);
        };
        reader.onerror = () => {
            toast.error('Sorry but there was an error reading your image file');
        };
        reader.readAsDataURL(file);
    };
    const dropHandler = (ev) => {
        ev.preventDefault();
        const file = ev.dataTransfer.files[0];
        validateFile(file);
    };
    const onFileChange = (ev) => {
        ev.preventDefault();
        if (ev.target.files)
            validateFile(ev.target.files[0]);
    };
    const dragOverHandler = (ev) => { ev.preventDefault(); };
    const onCloseLayer = () => {
        setShowEditor(false);
        if (input.current)
            input.current.value = '';
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { margin: "none", pad: "none", direction: "row", gap: "small" },
            React.createElement(Box, { gap: "small" },
                React.createElement(Box, { pad: "medium", border: "all", onClick: onDragClick, title: "Drag your file or click here", onDrop: dropHandler, onDragOver: dragOverHandler },
                    React.createElement(Anchor, null, "Drag your file or click here"),
                    React.createElement(Paragraph, { margin: "none", fill: true, textAlign: "center", size: "11px" }, "Max size: 500k"),
                    React.createElement("input", { type: "file", name: "inputImage", ref: input, style: { display: 'none' }, accept: "image/*", multiple: false, onChange: onFileChange })),
                (showErrorMessage) && (React.createElement(Box, { background: "status-critical" },
                    React.createElement(Paragraph, { fill: true, textAlign: "center" }, errorMessage)))),
            React.createElement(Box, { justify: "center", align: "center", flex: true, border: "all" },
                React.createElement(Stack, { anchor: "top-right", fill: true },
                    React.createElement(Box, { fill: "horizontal", align: "center", justify: "center", pad: "none", margin: "none", gap: "small" },
                        React.createElement(Image, { alt: "Uploaded image", src: image, style: {
                                maxHeight: 300,
                            } })),
                    React.createElement(Box, { background: "brand", pad: "xxsmall" },
                        React.createElement(Text, { size: "xsmall" }, t('ImageUploader:Preview')))))),
        (showEditor) && (React.createElement(ImageEditorLayer, { onCloseLayer: onCloseLayer, b64Image: imageBase64, onSaveResult: onSaveResult, aspect: aspect }))));
});
ImageUploader.displayName = 'ImageUploader';
export default ImageUploader;
