import React, { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { withTranslation } from 'react-i18next';
import { Box, Button, FormField, TextInput, } from 'grommet';
import { Save } from 'grommet-icons';
import { toast } from 'react-toastify';
import MDEditorDynamic from '../../../MDEditor/DynamicMDEditor';
import PageTitle from '../../../PageTitle/PageTitle';
import initialValues from './initialValues';
import FormWrapper from '../../../FormWrapper/FormWrapper';
import setUserProfile from './setUserProfile';
import Loading from '../../../Loading/Loading';
import getUserProfile from './getUserProfile';
const ChangeProfile = withTranslation()((props) => {
    const { t } = props;
    const [values, setValues] = useState(initialValues('', ''));
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const onChange = (value) => {
        setValues(value);
    };
    const getData = async () => {
        setLoading(true);
        await getUserProfile()
            .then((data) => {
            if (data.Success) {
                setValues(data.Object);
                setLoading(false);
            }
            else {
                toast.error('Sorry, but there was an error getting data');
            }
        })
            .catch(() => {
            toast.error('Sorry, but there was an error getting data');
        });
    };
    useEffect(() => {
        getData();
    }, []);
    const submit = async (event) => {
        const { value } = event;
        setSubmitting(true);
        await setUserProfile(value)
            .then((data) => {
            if (data.Success) {
                toast.success(t('ManageAccount:ChangeProfile.Feedback.Success'));
                setSubmitting(false);
            }
            else {
                toast.error(t('ManageAccount:ChangeProfile.Feedback.Failure'));
                setSubmitting(false);
            }
        })
            .catch(() => {
            toast.error(t('ManageAccount:ChangeProfile.Feedback.Failure'));
            setSubmitting(false);
        });
    };
    return (loading) ? (React.createElement(Loading, null)) : (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { text: t('ManageAccount:ChangeProfile.Title'), level: "4", margin: {
                top: 'none',
                bottom: 'small',
            } }),
        React.createElement(FormWrapper, { onChange: onChange, values: values, onSubmit: submit },
            React.createElement(FormField, { label: t('ManageAccount:ChangeProfile.NickName.Text'), width: "large", name: "NickName", validate: [
                    (fName) => {
                        if (fName.length < 2)
                            return t('ManageAccount:ChangeProfile.NickName.Invalid');
                        return undefined;
                    },
                ] },
                React.createElement(TextInput, { name: "NickName", "aria-label": t('ManageAccount:ChangeProfile.NickName.Title'), title: t('ManageAccount:ChangeProfile.NickName.Title') })),
            React.createElement(FormField, { label: t('ManageAccount:ChangeProfile.Profile.Text'), width: "large", name: "UserProfile", validate: [
                    (lName) => {
                        if (lName.length < 2)
                            return t('ManageAccount:ChangeProfile.Profile.Invalid');
                        return undefined;
                    },
                ] },
                React.createElement(MDEditorDynamic, { value: values.UserProfile, onChange: (value) => { setValues({ ...values, UserProfile: value }); }, fallback: React.createElement(Loading, null) })),
            React.createElement(Box, { direction: "row", justify: "between", margin: { top: 'large' } },
                React.createElement(Button, { icon: React.createElement(Save, null), type: "submit", label: t('ManageAccount:ChangeProfile.Submit:Text'), title: t('ManageAccount:ChangeProfile.Submit:Title'), primary: true, reverse: true, disabled: submitting })))));
});
ChangeProfile.displayName = 'ChangeProfile';
export default ChangeProfile;
