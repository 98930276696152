import { Anchor, Box, Heading } from 'grommet';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LoginForm from './LoginForm';
import useLanguage from './Language';
import onSubmit from './onSubmit';
const Login = withTranslation()((props) => {
    const { t } = props;
    useLanguage();
    const history = useHistory();
    return (React.createElement(Box, { fill: "horizontal", pad: "small" },
        React.createElement(Heading, { level: 3, textAlign: "center", fill: true }, t('Login:Title')),
        React.createElement(LoginForm, { onSubmit: onSubmit }),
        React.createElement(Box, { justify: "center", align: "center", fill: "horizontal", pad: "small" },
            React.createElement(Anchor, { size: "large", label: "Forgot password", title: t('Login:Forgot.Title'), onClick: () => { history.push('/passwordRecovery'); } }))));
});
Login.displayName = 'Login';
export default Login;
