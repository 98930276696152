import { Box, Heading, Paragraph } from 'grommet';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PageWrapper from '../PageWrapper/PageWrapper';
const Home = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const [page, setPage] = useState({});
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const { PageTitle, PageText } = window.initialData;
            if (PageTitle && PageTitle !== '' && PageText && PageText !== '')
                setPage({ PageTitle, PageText });
        }
    }, []);
    return (React.createElement(PageWrapper, null,
        React.createElement("iframe", { src: "/public/SolarSystem/solarSystem.html", title: "Solar system", style: {
                border: 0,
                height: 300,
            } }),
        React.createElement(Heading, { level: "2", fill: true }, page.PageTitle),
        React.createElement(Box, null,
            React.createElement(Paragraph, { fill: true }, page.PageText))));
});
Home.displayName = 'Home';
export default Home;
