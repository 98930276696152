export default {
    Title: 'Usamos cookies para mejorar su experiencia.',
    Link: {
        Text: '¿Le gustaría leer nuestra política de privacidad?',
        Title: 'Haga clic aquí para leer nuestra política de privacidad',
    },
    Button: {
        Text: 'Descartar',
        Title: 'Haga clic aquí para cerrar esta capa',
    },
};
