import { Button, Image, Nav } from 'grommet';
import { Login, Services } from 'grommet-icons';
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { withTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import { useStateValue } from '../../AppContext/StateProvider';
import HeaderAppBar from './AppBar';
import ConfigLayer from './ConfigLayer';
import IdentityManagerLinks from './IdentityManagerLinks';
import setLanguage from './Language';
const Header = withTranslation()((props) => {
    const { t } = props;
    const [{ IsAuthenticated, Config }, dispatch] = useStateValue();
    const history = useHistory();
    setLanguage();
    const changeConfig = () => {
        dispatch({
            type: 'changeConfig',
            value: !Config,
        });
    };
    return (React.createElement(HeaderAppBar, null,
        React.createElement(NavLink, { to: "/" },
            React.createElement(Image, { src: "/public/logo.png", alt: "Harmony with Nature", style: {
                    maxHeight: 100,
                    maxWidth: '100%',
                } })),
        React.createElement(Nav, { direction: "row" },
            (IsAuthenticated) ? (React.createElement(IdentityManagerLinks, null)) : (React.createElement(Button, { icon: React.createElement(Login, null), title: t('Header:Login.Title'), onClick: () => { history.push('/identity'); } })),
            React.createElement(Button, { icon: React.createElement(Services, null), title: t('Header:Config.Title'), onClick: changeConfig })),
        (Config) && React.createElement(ConfigLayer, null)));
});
Header.displayName = 'Header';
export default Header;
