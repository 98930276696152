import { Box, Grid, ResponsiveContext, } from 'grommet';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PageWrapper from '../PageWrapper/PageWrapper';
import Login from './Login/Login';
import { useStateValue } from '../AppContext/StateProvider';
import Loading from '../Loading/Loading';
const Identity = withTranslation()((props) => {
    const { t } = props;
    const size = useContext(ResponsiveContext);
    const history = useHistory();
    const [{ IsAuthenticated }] = useStateValue();
    const [loading, setLoading] = useState(true);
    const checkAuth = () => {
        if (IsAuthenticated) {
            history.push('/manageAccount/0');
        }
        setLoading(false);
    };
    useEffect(() => {
        checkAuth();
    }, []);
    return (loading) ? (React.createElement(Loading, null)) : (React.createElement(PageWrapper, null,
        React.createElement(Grid, { columns: (size !== 'small') ? ['1/2', '1/2'] : '100%', gap: "small", pad: {
                top: 'medium',
            } },
            React.createElement(Box, { fill: "horizontal" },
                React.createElement(Login, null)),
            React.createElement(Box, { fill: "horizontal", justify: "center", align: "center", pad: "small", margin: "none", border: (size !== 'small') ? {
                    side: 'left',
                    size: 'xsmall',
                } : {
                    side: 'top',
                    size: 'xsmall',
                } }, t('Identity:Create.Title')))));
});
Identity.displayName = 'Identity';
export default Identity;
