export default {
    Title: 'We use cookies to improve your experience.',
    Link: {
        Text: 'Would you like to read our privacy policy?',
        Title: 'Click here to read our privacy policy',
    },
    Button: {
        Text: 'Dismiss',
        Title: 'Click here to close this layer',
    },
};
