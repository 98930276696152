import React from 'react';
import { Grommet, Grid, Box } from 'grommet';
import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GrommetTheme from './GrommetTheme';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useStateValue } from '../../AppContext/StateProvider';
import CookiesConsent from './CookiesConsent/CookiesConsent';
const GrommetWrapper = withTranslation()((props) => {
    const { children } = props;
    const [{ Theme = 'light', ConsentCookie = {} }] = useStateValue();
    const { ShowBanner = false, CookieString = '' } = ConsentCookie;
    return (React.createElement(Grommet, { full: "min", theme: GrommetTheme, themeMode: (Theme === 'light') ? 'light' : 'dark', style: {
            minHeight: '100vh',
        } },
        React.createElement(ToastContainer, { position: "bottom-right", autoClose: 5000, hideProgressBar: true, newestOnTop: true, closeOnClick: false, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true }),
        React.createElement(Grid, { fill: "vertical", rows: ['auto', 'flex', 'auto'], columns: ['auto'], areas: [
                ['header'],
                ['main'],
                ['footer'],
            ], style: {
                minHeight: '100vh',
            } },
            React.createElement(Box, { gridArea: "header" },
                React.createElement(Header, null)),
            React.createElement(Box, { gridArea: "main" },
                children,
                (ShowBanner) && (React.createElement(CookiesConsent, { ShowBanner: ShowBanner, CookieString: CookieString }))),
            React.createElement(Box, { gridArea: "footer" },
                React.createElement(Footer, null)))));
});
GrommetWrapper.displayName = 'GrommetWrapper';
export default GrommetWrapper;
