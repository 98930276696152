import { Box, Button, Layer, RangeInput, Text, } from 'grommet';
import { Close, Save } from 'grommet-icons';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { withTranslation } from 'react-i18next';
import Cropper from 'react-easy-crop';
import PageTitle from '../PageTitle/PageTitle';
import getCroppedImg from './getCroppedImage';
const ImageEditorLayer = withTranslation()((props) => {
    const { onCloseLayer, b64Image, onSaveResult, aspect = 1, } = props;
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({
        width: 0, height: 0, x: 0, y: 0,
    });
    const onCropComplete = useCallback((croppedArea, completedCroppedAreaPixels) => {
        setCroppedAreaPixels(completedCroppedAreaPixels);
    }, []);
    const onSave = async () => {
        await getCroppedImg(b64Image, croppedAreaPixels, rotation)
            .then((result) => {
            if (onSaveResult)
                onSaveResult(result);
            onCloseLayer();
        });
    };
    return (React.createElement(Layer, { full: true, animation: "fadeIn" },
        React.createElement(Box, { fill: true, align: "center", justify: "start", pad: "small", gap: "small", direction: "column" },
            React.createElement(Box, { fill: "horizontal", align: "end" },
                React.createElement(Button, { icon: React.createElement(Close, null), onClick: onCloseLayer })),
            React.createElement(Box, { fill: "horizontal" },
                React.createElement(PageTitle, { text: "Edit your image" })),
            React.createElement(Box, { fill: "horizontal", align: "center", style: {
                    position: 'relative',
                    height: 400,
                } },
                React.createElement(Cropper, { image: b64Image, crop: crop, zoom: zoom, rotation: rotation, aspect: aspect, objectFit: "contain", onCropChange: setCrop, onCropComplete: onCropComplete, onZoomChange: setZoom })),
            React.createElement(Box, { fill: "horizontal", align: "center", direction: "row", gap: "medium" },
                React.createElement(Box, { width: "50%", align: "center", direction: "column" },
                    React.createElement(Text, null, "Zoom"),
                    React.createElement(Box, { align: "center", pad: {
                            vertical: 'small',
                        }, fill: "horizontal" },
                        React.createElement(RangeInput, { value: zoom, min: 1, max: 3, step: 0.1, onChange: (evt) => { setZoom(parseFloat(evt.target.value)); } }))),
                React.createElement(Box, { width: "50%", align: "center", direction: "column" },
                    React.createElement(Text, null, "Rotation"),
                    React.createElement(Box, { align: "center", pad: {
                            vertical: 'small',
                        }, fill: "horizontal" },
                        React.createElement(RangeInput, { value: rotation, min: 0, max: 360, step: 1, onChange: (evt) => { setRotation(parseInt(evt.target.value, 10)); } })))),
            React.createElement(Box, { fill: "horizontal", justify: "center", align: "baseline", gap: "medium", direction: "row" },
                React.createElement(Button, { primary: true, label: "Save", title: "Save image", size: "large", color: "status-ok", icon: React.createElement(Save, null), onClick: () => { onSave(); } }),
                React.createElement(Button, { primary: true, label: "Cancel", title: "Cancel editing", color: "status-critical", icon: React.createElement(Close, null), onClick: onCloseLayer })))));
});
ImageEditorLayer.displayName = 'ImageEditorLayer';
export default ImageEditorLayer;
