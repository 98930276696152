import ChangeNameEng from './ChangeName/LanguageENG';
import ChangeEmailEng from './ChangeEmail/LanguageENG';
import ChangePasswordEng from './ChangePassword/LanguageENG';
import ChangeAdditionalInfoEng from './ChangeAdditionalInfo/LanguageENG';
import ChangeProfileEng from './ChangeProfile/LanguageENG';
import ChangePictureEng from './ChangePicture/LanguageENG';
export default {
    Title: 'Manage your account',
    Name: 'Name',
    Password: 'Password',
    Additional: 'Additional info',
    Image: 'Image',
    Profile: 'Profile',
    Warning: {
        Text: 'Attention! Updating any of the fields below will result in being logged out of your account. You will need to sign back in.',
    },
    ChangeName: ChangeNameEng,
    ChangeEmail: ChangeEmailEng,
    ChangePassword: ChangePasswordEng,
    ChangeAdditionalInfo: ChangeAdditionalInfoEng,
    ChangeProfile: ChangeProfileEng,
    ChangePicture: ChangePictureEng,
};
