import React, { useEffect, useState } from 'react';
import { Box, Button, FormField, TextInput, } from 'grommet';
import { Save } from 'grommet-icons';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageTitle from '../../../PageTitle/PageTitle';
import initialValues from './initialValues';
import FormWrapper from '../../../FormWrapper/FormWrapper';
import setAdditionalInfo from './setAdditionalInfo';
import Loading from '../../../Loading/Loading';
import getAdditionalInfo from './getAdditionalInfo';
const ChangeAdditionalInfo = withTranslation()((props) => {
    const { t } = props;
    const [values, setValues] = useState(initialValues('', '', ''));
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const onChange = (value) => {
        setValues(value);
    };
    const getData = async () => {
        setLoading(true);
        await getAdditionalInfo()
            .then((data) => {
            if (data.Success) {
                setValues(data.Object);
                setLoading(false);
            }
            else {
                toast.error('Sorry, but there was an error getting data');
            }
        })
            .catch(() => {
            toast.error('Sorry, but there was an error getting data');
        });
    };
    useEffect(() => {
        getData();
    }, []);
    const submit = async (event) => {
        const { value } = event;
        setSubmitting(true);
        await setAdditionalInfo(value)
            .then((data) => {
            if (data.Success) {
                toast.success(t('ManageAccount:ChangeAdditionalInfo.Feedback.Success'));
                setSubmitting(false);
            }
            else {
                toast.error(t('ManageAccount:ChangeAdditionalInfo.Feedback.Failure'));
                setSubmitting(false);
            }
        })
            .catch(() => {
            toast.error(t('ManageAccount:ChangeAdditionalInfo.Feedback.Failure'));
            setSubmitting(false);
        });
    };
    return (loading) ? (React.createElement(Loading, null)) : (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { text: t('ManageAccount:ChangeAdditionalInfo.Title'), level: "4", margin: {
                top: 'none',
                bottom: 'small',
            } }),
        React.createElement(FormWrapper, { onChange: onChange, values: values, onSubmit: submit },
            React.createElement(FormField, { label: t('ManageAccount:ChangeAdditionalInfo.Country.Text'), width: "large", name: "Country", validate: [
                    (fName) => {
                        if (fName.length < 2)
                            return t('ManageAccount:ChangeAdditionalInfo.Country.Invalid');
                        return undefined;
                    },
                ] },
                React.createElement(TextInput, { name: "Country", "aria-label": t('ManageAccount:ChangeAdditionalInfo.Country.Title'), title: t('ManageAccount:ChangeAdditionalInfo.Country.Title') })),
            React.createElement(FormField, { label: t('ManageAccount:ChangeAdditionalInfo.State.Text'), width: "large", name: "State", validate: [
                    (lName) => {
                        if (lName.length < 2)
                            return t('ManageAccount:ChangeAdditionalInfo.State.Invalid');
                        return undefined;
                    },
                ] },
                React.createElement(TextInput, { name: "State", "aria-label": t('ManageAccount:ChangeAdditionalInfo.State.Title'), title: t('ManageAccount:ChangeAdditionalInfo.State.Title') })),
            React.createElement(FormField, { label: t('ManageAccount:ChangeAdditionalInfo.Phone.Text'), width: "large", name: "Phone", validate: [
                    (Password) => {
                        if (Password.length < 6 || Password.length > 15)
                            return t('ManageAccount:ChangeName.Phone.Invalid');
                        return undefined;
                    },
                ] },
                React.createElement(TextInput, { name: "Phone", "aria-label": t('ManageAccount:ChangeAdditionalInfo.Phone.Title'), title: t('ManageAccount:ChangeAdditionalInfo.Phone.Title') })),
            React.createElement(Box, { direction: "row", justify: "between", margin: { top: 'large' } },
                React.createElement(Button, { icon: React.createElement(Save, null), type: "submit", label: t('ManageAccount:ChangeAdditionalInfo.Submit:Text'), title: t('ManageAccount:ChangeAdditionalInfo.Submit:Title'), primary: true, reverse: true, disabled: submitting })))));
});
ChangeAdditionalInfo.displayName = 'ChangeAdditionalInfo';
export default ChangeAdditionalInfo;
