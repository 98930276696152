const ChangeAdditionalInfoEng = {
    Title: 'Change your additional info',
    Country: {
        Text: 'Country',
        Title: 'Please inform your country',
    },
    State: {
        Text: 'State',
        Title: 'Please inform your state',
    },
    Phone: {
        Text: 'Phone',
        Title: 'Please inform your phone',
    },
    Submit: {
        Text: 'Save',
        Title: 'Click here to save your info',
    },
    Feedback: {
        Success: 'Info successfully updated',
        Failure: 'Sorry, but there was a problem updating your info',
    },
};
export default ChangeAdditionalInfoEng;
