const ChangeEmailPTBR = {
    Title: 'Alterar o email',
    Email: {
        Text: 'Email',
        Title: 'Por favor informe teu email',
        Invalid: 'Deve ser um endereço de email válido',
    },
    Password: {
        Text: 'Confirme com a tua senha',
        Title: 'Por favor confirme com tua senha',
        Invalid: 'Senhas devem ser 6-15 caracteres',
    },
    Submit: {
        Text: 'Salvar',
        Title: 'Clique aqui para salvar',
    },
    Feedback: {
        Success: 'Email atualizado com sucesso',
        Failure: 'Desculpe, mas houve um problema ao tentar atualizar teu email. Talvez a senha esteja errada.',
    },
};
export default ChangeEmailPTBR;
