import { Layer } from 'grommet';
import React from 'react';
import { withTranslation } from 'react-i18next';
import CookiesConsentContent from './CookiesConsentContent';
const CookiesConsent = withTranslation()((props) => {
    const { ShowBanner, CookieString } = props;
    return (!ShowBanner) ? React.createElement(React.Fragment, null) : (React.createElement(Layer, { modal: false, responsive: false, full: "horizontal", position: "bottom" },
        React.createElement(CookiesConsentContent, { CookieString: CookieString, ShowBanner: ShowBanner })));
});
CookiesConsent.displayName = 'CookiesConsent';
export default CookiesConsent;
