const ChangePasswordPTBR = {
    Title: 'Alterar a senha',
    NewPassword: {
        Text: 'Nova senha',
        Title: 'Por favor informe a nova senha',
        Invalid: 'Senhas devem ser 6-15 caracteres',
    },
    ConfirmPassword: {
        Text: 'Confirmar senha',
        Title: 'Por favor confirme a nova senha',
        Invalid: 'Senha e confirmar senha devem ser iguais',
    },
    Password: {
        Text: 'Confirme com a tua senha',
        Title: 'Por favor confirme com tua senha',
        Invalid: 'Senhas devem ser 6-15 caracteres',
    },
    Submit: {
        Text: 'Salvar',
        Title: 'Clique aqui para salvar',
    },
    Feedback: {
        Success: 'Senha atualizada com sucesso',
        Failure: 'Desculpe, mas houve um problema ao tentar atualizar tua senha. Talvez a senha atual esteja errada.',
    },
};
export default ChangePasswordPTBR;
