// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from 'axios';
const Axios = () => {
    const myAxios = axios.create({
        baseURL: '/api/',
        withCredentials: true,
    });
    // myAxios.interceptors.response.use((response): AxiosResponse => (response),
    //   (error: AxiosError): void => {
    //     if (error.response?.status === 403) {
    //       window.location.href = '/403';
    //     }
    //   });
    return myAxios;
};
export default Axios;
