import Axios from '../../../../Utils/axios';
const getAdditionalInfo = async () => {
    let info = {};
    await Axios().get('Identity/GetUserAdditionalInfo')
        .then((response) => {
        const { data } = response;
        info = data;
        return info;
    })
        .catch(() => {
        info.Success = false;
        return info;
    });
    return info;
};
export default getAdditionalInfo;
