import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ImageUploader from '../../../ImageUploader/ImageUploader';
import PageTitle from '../../../PageTitle/PageTitle';
import setNewPicture from './setNewPicture';
import Loading from '../../../Loading/Loading';
import getUserPicture from './getUserPicture';
const ChangePicture = withTranslation()((props) => {
    const { t } = props;
    const [loading, setLoading] = useState(true);
    const [picture, setPicture] = useState('');
    const getData = async () => {
        await getUserPicture()
            .then((data) => {
            if (data.Success) {
                setPicture(`/api/upload/GetUserImage/${data.Object}`);
                setLoading(false);
            }
        })
            .catch(() => {
            toast.error('Sorry but there was an error getting your profile image');
        });
    };
    const onSave = async (b64) => {
        const upload = {
            UploadBase64: b64,
            UploadExtension: 'png',
        };
        setLoading(true);
        await setNewPicture(upload)
            .then((data) => {
            getData();
            if (data.Success) {
                toast.success(t('ManageAccount:ChangePicture.Feedback.Success'));
            }
        })
            .catch(() => {
            toast.error(t('ManageAccount:ChangePicture.Feedback.Failure'));
        });
    };
    useEffect(() => {
        getData();
    }, []);
    return (loading) ? (React.createElement(Loading, null)) : (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { text: t('ManageAccount:ChangePicture.Title'), level: "4", margin: {
                top: 'none',
                bottom: 'small',
            } }),
        React.createElement(ImageUploader, { onSaveResult: onSave, image: picture, aspect: 1 })));
});
ChangePicture.displayName = 'ChangePicture';
export default ChangePicture;
