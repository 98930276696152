const ChangePasswordEng = {
    Title: 'Change your password',
    NewPassword: {
        Text: 'New password',
        Title: 'Please inform your new password',
        Invalid: 'Password must be 6-15 characters',
    },
    ConfirmPassword: {
        Text: 'Confirm password',
        Title: 'Please confirm your new password',
        Invalid: 'New password and Confirm password must be the same',
    },
    Password: {
        Text: 'Confirm with password',
        Title: 'Please confirm with your password',
        Invalid: 'Password must be 6-15 characters',
    },
    Submit: {
        Text: 'Save',
        Title: 'Click here to save your info',
    },
    Feedback: {
        Success: 'Password successfully updated',
        Failure: 'Sorry, but there was a problem updating your new password. Maybe wrong credentials.',
    },
};
export default ChangePasswordEng;
