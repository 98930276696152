export default {
    Title: 'Sign in to your account',
    Email: {
        Title: 'Please inform your email',
        Text: 'Email',
        Invalid: 'Invalid email address',
        Placeholder: 'your-email@email.com',
    },
    Password: {
        Title: 'Please inform your password',
        Text: 'Password',
        Invalid: 'Minimum of 6 characters and maximum of 15 characters',
    },
    KeepConnected: {
        Title: 'Stay connected for 30 days',
        Text: 'Stay connected',
    },
    Submit: {
        Text: 'Enter',
        Title: 'Please click here to enter',
    },
    Feedback: {
        Success: 'Successfully logged in',
        Failure: 'Sorry, but there was an error. Maybe wrong credentials',
    },
    Forgot: {
        Text: 'Forgot your password?',
        Title: 'Click here to receive a new password in your email',
    },
};
