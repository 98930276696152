import { Box, Button, CheckBox, FormField, TextInput, } from 'grommet';
import { Login } from 'grommet-icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import FormWrapper from '../../FormWrapper/FormWrapper';
import initialValues from './initialValues';
import { useStateValue } from '../../AppContext/StateProvider';
const LoginForm = withTranslation()((props) => {
    const { t, onSubmit } = props;
    const history = useHistory();
    const [, dispatch] = useStateValue();
    const [values, setValues] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const onChange = (value) => {
        setValues(value);
    };
    const submit = async (event) => {
        const { value } = event;
        setLoading(true);
        await onSubmit(value)
            .then((data) => {
            if (data.Success) {
                toast.success(t('Login:Feedback.Success'));
                const user = data.Object;
                dispatch({
                    type: 'changeFirstName',
                    value: user.FirstName,
                });
                dispatch({
                    type: 'changeLastName',
                    value: user.LastName,
                });
                dispatch({
                    type: 'changeEmail',
                    value: user.Email,
                });
                dispatch({
                    type: 'changeAuth',
                    value: true,
                });
                dispatch({
                    type: 'changeDrawer',
                    value: true,
                });
                dispatch({
                    type: 'changeValidated',
                    value: user.Validated,
                });
                dispatch({
                    type: 'changeTheme',
                    value: user.Theme,
                });
                dispatch({
                    type: 'changeLanguage',
                    value: user.Language,
                });
                history.push('/');
            }
            else {
                setLoading(false);
                toast.error(t('Login:Feedback.Failure'));
            }
        })
            .catch(() => {
            setLoading(false);
            toast.error(t('Login:Feedback.Failure'));
        });
    };
    return (React.createElement(Box, { align: "center", justify: "center", width: "large", pad: {
            bottom: 'medium',
        }, border: {
            side: 'bottom',
            size: 'xsmall',
        } },
        React.createElement(FormWrapper, { values: values, onChange: onChange, onSubmit: submit },
            React.createElement(FormField, { label: t('Login:Email.Text'), name: "Email", width: "medium", required: true, validate: {
                    // eslint-disable-next-line max-len
                    regexp: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                    message: t('Login:Email.Invalid'),
                } },
                React.createElement(TextInput, { name: "Email", type: "email", "aria-label": t('Login:Email.Title'), title: t('Login:Email.Title'), placeholder: t('Login:Email.Placeholder') })),
            React.createElement(FormField, { label: t('Login:Password.Text'), width: "medium", name: "Password", required: true, validate: [
                    (Password) => {
                        if (Password.length < 6 || Password.length > 15)
                            return t('Login:Password.Invalid');
                        return undefined;
                    },
                ] },
                React.createElement(TextInput, { name: "Password", type: "password", "aria-label": t('Login:Password.Title'), title: t('Login:Password.Title'), placeholder: "******" })),
            React.createElement(FormField, { name: "KeepConnected" },
                React.createElement(CheckBox, { name: "KeepConnected", label: t('Login:KeepConnected:Text'), title: t('Login:KeepConnected:Title'), a11yTitle: t('Login:KeepConnected:Title'), checked: values.KeepConnected })),
            React.createElement(Box, { direction: "row", justify: "between", margin: { top: 'large' } },
                React.createElement(Button, { icon: React.createElement(Login, null), type: "submit", label: t('Login:Submit:Text'), title: t('Login:Submit:Title'), primary: true, reverse: true, disabled: loading })))));
});
LoginForm.displayName = 'LoginForm';
export default LoginForm;
