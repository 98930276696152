const ChangeNameSpa = {
    Title: 'Cambia tu nombre',
    FirstName: {
        Text: 'Nombre de pila',
        Title: 'Por favor, informe su nombre',
        Invalid: 'Debe tener al menos 2 caracteres',
    },
    LastName: {
        Text: 'Apellido',
        Title: 'Por favor informe su apellido',
        Invalid: 'Debe tener al menos 2 caracteres',
    },
    Password: {
        Text: 'Confirmar con contraseña',
        Title: 'Usa tu contraseña para confirmar',
        Invalid: 'Mínimo 6 caracteres e máximo de 15 caracteres',
    },
    Submit: {
        Text: 'Salvar',
        Title: 'Haga clic aquí para guardar los datos',
    },
    Feedback: {
        Success: 'Datos enviados con éxito',
        Failure: '¡UPS! Algo sucedió en la presentación. Por favor, revise los datos y envíelos nuevamente. Quizás credenciales incorrectas.',
    },
};
export default ChangeNameSpa;
