import i18next from 'i18next';
import { useEffect } from 'react';
import LanguageSPA from './LanguageSPA';
import LanguageENG from './LanguageENG';
import LanguagePTBR from './LanguagePTBR';
export default () => {
    i18next.addResourceBundle('SPA', 'Header', LanguageSPA);
    i18next.addResourceBundle('ENG', 'Header', LanguageENG);
    useEffect(() => {
        if (!i18next.hasResourceBundle('SPA', 'Header')) {
            i18next.addResourceBundle('SPA', 'Header', LanguageSPA);
        }
        if (!i18next.hasResourceBundle('ENG', 'Header')) {
            i18next.addResourceBundle('ENG', 'Header', LanguageENG);
        }
        if (!i18next.hasResourceBundle('PTBR', 'Header')) {
            i18next.addResourceBundle('PTBR', 'Header', LanguagePTBR);
        }
        // return type void != (): void... so as unknown as void
        return (() => {
            i18next.removeResourceBundle('SPA', 'Header');
            i18next.removeResourceBundle('ENG', 'Header');
            i18next.removeResourceBundle('PTBR', 'Header');
        });
    }, []);
};
