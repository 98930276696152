const ChangeNameEng = {
    Title: 'Change your name',
    FirstName: {
        Text: 'First name',
        Title: 'Please inform your first name',
        Invalid: 'Please inform at least 2 characters',
    },
    LastName: {
        Text: 'Last name',
        Title: 'Please inform your last name',
        Invalid: 'Please inform at least 2 characters',
    },
    Password: {
        Text: 'Confirm with password',
        Title: 'Please confirm with your password',
        Invalid: 'Password must be 6-15 characters',
    },
    Submit: {
        Text: 'Save',
        Title: 'Click here to save your info',
    },
    Feedback: {
        Success: 'Name successfully updated',
        Failure: 'Sorry, but there was a problem updating your name. Maybe wrong credentials.',
    },
};
export default ChangeNameEng;
