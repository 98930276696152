const ChangePictureEng = {
    Title: 'Cambia tu fotografía',
    Submit: {
        Text: 'Salvar',
        Title: 'Haga clic aquí para guardar su nueva imagen',
    },
    Feedback: {
        Success: 'Imagen actualizada correctamente',
        Failure: 'Lo sentimos, pero hubo un problema al actualizar tu imagen',
    },
};
export default ChangePictureEng;
