import Axios from '../../../Utils/axios';
const saveConfig = async (values) => {
    let config = {};
    await Axios().post('Identity/SetUserPreferences', values)
        .then((response) => {
        const { data } = response;
        config = data;
        return config;
    })
        .catch(() => {
        config.Success = false;
        return config;
    });
    return config;
};
export default saveConfig;
