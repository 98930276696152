import React, { createContext, useContext, useReducer } from 'react';
export const StateContext = createContext({});
export const StateProvider = (props) => {
    const { reducer, children, initialContext } = props;
    return (React.createElement(StateContext.Provider, { value: useReducer(reducer, initialContext) }, children));
};
export const useStateValue = () => useContext(StateContext);
export const withContext = (Component) => (props) => (React.createElement(StateContext.Consumer, null, (context) => (
// eslint-disable-next-line react/jsx-props-no-spreading
React.createElement(Component, { ...props, context: context }))));
