export default {
    Title: 'Entre em tua conta',
    Email: {
        Title: 'Por favor informe o email',
        Text: 'Email',
        Invalid: 'endereço de e-mail inválido',
        Placeholder: 'teu-email@email.com',
    },
    Password: {
        Title: 'Por favor informe a senha',
        Text: 'Senha',
        Invalid: 'Mínimo de 6 e máximo de 15 caracteres',
    },
    KeepConnected: {
        Title: 'Manter-me conectado por 30 dias',
        Text: 'Manter-me conectado',
    },
    Submit: {
        Text: 'Entrar',
        Title: 'Clique aqui para fazer login',
    },
    Feedback: {
        Success: 'Login feito com sucesso',
        Failure: 'Desculpe, mas houve um erro. Talvez credenciais erradas',
    },
    Forgot: {
        Text: 'Esqueceu a senha?',
        Title: 'Clique aqui para recuperá-la',
    },
};
