import { Box, Heading, Text } from 'grommet';
import { Alert } from 'grommet-icons';
import React from 'react';
const Warning = (props) => {
    const { status = 'status-unknown', title, text } = props;
    return (React.createElement(Box, { direction: "row", fill: "horizontal", background: status, gap: "medium", pad: "medium", justify: "start", align: "center" },
        React.createElement(Box, null,
            React.createElement(Alert, { size: "large" })),
        React.createElement(Box, null,
            (title) && (React.createElement(Heading, { level: "4", margin: {
                    bottom: 'small',
                    top: 'none',
                } }, title)),
            (text) && (React.createElement(Text, { margin: {
                    bottom: 'none',
                    top: 'none',
                } }, text)))));
};
Warning.displayName = 'Warning';
export default Warning;
