export default {
    DropButton: {
        Manage: {
            Text: 'Gerenciar conta',
            Title: 'Clique aqui para gerenciar tua conta',
        },
        Logout: {
            Text: 'Logout',
            Title: 'Clique aqui para encerrar tua conexão',
        },
    },
    Login: {
        Title: 'Clique aqui para entrar ou criar uma nova conta',
    },
    Manage: {
        Title: 'Clique aqui para gerenciar tua conta',
    },
    Config: {
        Title: 'Personalize tua experiência',
        Theme: {
            Title: 'Tema',
            Dark: 'Escuro',
            Light: 'Claro',
        },
        Language: {
            Title: 'Idioma',
        },
        Save: {
            Text: 'Salvar',
            Title: 'Clique aqui para salvar tuas preferências',
        },
    },
};
