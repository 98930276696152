import ChangeNameSpa from './ChangeName/LanguageSPA';
import ChangeEmailSpa from './ChangeEmail/LanguageSPA';
import ChangePasswordSpa from './ChangePassword/LanguageSPA';
import ChangeAdditionalInfoSpa from './ChangeAdditionalInfo/LanguageSPA';
import ChangeProfileSpa from './ChangeProfile/LanguageSPA';
import ChangePictureSpa from './ChangePicture/LanguageSPA';
export default {
    Title: 'Administra tu cuenta',
    Name: 'Nombre',
    Password: 'Contraseña',
    Additional: 'Información adicional',
    Image: 'Imagen',
    Profile: 'Perfil',
    Warning: {
        // eslint-disable-next-line max-len
        Text: '¡Atención! La actualización de cualquiera de los campos a continuación resultará en que se cierre la sesión de su cuenta. Deberá volver a iniciar sesión.',
    },
    ChangeName: ChangeNameSpa,
    ChangeEmail: ChangeEmailSpa,
    ChangePassword: ChangePasswordSpa,
    ChangeAdditionalInfo: ChangeAdditionalInfoSpa,
    ChangeProfile: ChangeProfileSpa,
    ChangePicture: ChangePictureSpa,
};
