import Axios from '../../../Utils/axios';
const signout = async () => {
    let serverSignout = {};
    await Axios().get('Identity/SignOut')
        .then((response) => {
        const { data } = response;
        serverSignout = data;
        return serverSignout;
    })
        .catch(() => {
        serverSignout.Success = false;
        return serverSignout;
    });
    return serverSignout;
};
export default signout;
