import ChangeNamePTBR from './ChangeName/LanguagePTBR';
import ChangeEmailPTBR from './ChangeEmail/LanguagePTBR';
import ChangePasswordPTBR from './ChangePassword/LanguagePTBR';
import ChangeAdditionalInfoPTBR from './ChangeAdditionalInfo/LanguagePTBR';
import ChangeProfilePTBR from './ChangeProfile/LanguagePTBR';
import ChangePicturePTBR from './ChangePicture/LanguagePTBR';
export default {
    Title: 'Gerencie tua conta',
    Name: 'Nome',
    Password: 'Senha',
    Additional: 'Informações adicionais',
    Image: 'Foto',
    Profile: 'Perfil',
    Warning: {
        Text: 'Atenção! Atualizando quaisquer dos campos abaixo resultará em desconexão da plataforma. Você terá que fazer login novamente.',
    },
    ChangeName: ChangeNamePTBR,
    ChangeEmail: ChangeEmailPTBR,
    ChangePassword: ChangePasswordPTBR,
    ChangeAdditionalInfo: ChangeAdditionalInfoPTBR,
    ChangeProfile: ChangeProfilePTBR,
    ChangePicture: ChangePicturePTBR,
};
