const ChangeProfileEng = {
    Title: 'Change your profile',
    NickName: {
        Text: 'User nickname',
        Title: 'Please inform your user nickname',
        Invalid: 'Nicknames are an unique identifier',
    },
    Profile: {
        Text: 'Profile',
        Title: 'Please fill out your profile',
    },
    Submit: {
        Text: 'Save',
        Title: 'Click here to save your info',
    },
    Feedback: {
        Success: 'Info successfully updated',
        Failure: 'Sorry, but there was a problem updating your info',
    },
};
export default ChangeProfileEng;
