import Axios from '../../../../Utils/axios';
const setUserProfile = async (values) => {
    let change = {};
    await Axios().post('Identity/SetUserProfile', values)
        .then((response) => {
        const { data } = response;
        change = data;
        return change;
    })
        .catch(() => {
        change.Success = false;
        return change;
    });
    return change;
};
export default setUserProfile;
