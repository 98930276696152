const ChangeProfilePTBR = {
    Title: 'Alterar perfil',
    NickName: {
        Text: 'Apelido',
        Title: 'Por favor informe escolha um apelido',
        Invalid: 'Apelidos são identificadores únicos no sistema',
    },
    Profile: {
        Text: 'Profile',
        Title: 'Por favor preencha teu perfil',
    },
    Submit: {
        Text: 'Salvar',
        Title: 'Clique aqui para salvar',
    },
    Feedback: {
        Success: 'Informações atualizadas com sucesso',
        Failure: 'Desculpe, mas houve um problema ao tentar atualizar tuas informações',
    },
};
export default ChangeProfilePTBR;
