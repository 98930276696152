export default {
    DropButton: {
        Manage: {
            Text: 'Manage account',
            Title: 'Click here to manage your account',
        },
        Logout: {
            Text: 'Logout',
            Title: 'Click here to close your connection',
        },
    },
    Login: {
        Title: 'Click here to enter your account or create a new one',
    },
    Manage: {
        Title: 'Click here to manage your account',
    },
    Config: {
        Title: 'Configure your experience',
        Theme: {
            Title: 'Theme',
            Dark: 'Dark',
            Light: 'Light',
        },
        Language: {
            Title: 'Language',
        },
        Save: {
            Text: 'Save',
            Title: 'Click here to save your prefferences',
        },
    },
};
