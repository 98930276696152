import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const resources = {
    ENG: {},
    SPA: {},
    PTBR: {},
};
export default i18n
    .use(initReactI18next)
    .init({
    lng: 'ENG',
    fallbackLng: 'ENG',
    resources,
    debug: false,
    keySeparator: '.',
    interpolation: {
        escapeValue: false,
    },
})
    .then((t) => t);
