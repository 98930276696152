const ChangeNamePTBR = {
    Title: 'Alterar o nome',
    FirstName: {
        Text: 'Primeiro nome',
        Title: 'Por favor informe teu primeiro nome',
        Invalid: 'Por favor informe ao menos 2 caracteres',
    },
    LastName: {
        Text: 'Sobrenome',
        Title: 'Por favor informe teu sobrenome',
        Invalid: 'Por favor informe ao menos 2 caracteres',
    },
    Password: {
        Text: 'Confirme com a tua senha',
        Title: 'Por favor confirme com tua senha',
        Invalid: 'Senhas devem ser 6-15 caracteres',
    },
    Submit: {
        Text: 'Salvar',
        Title: 'Clique aqui para salvar',
    },
    Feedback: {
        Success: 'Nome atualizado com sucesso',
        Failure: 'Desculpe, mas houve um problema ao tentar atualizar teu nome. Talvez a senha esteja errada.',
    },
};
export default ChangeNamePTBR;
