import { Box, Tab, Tabs, } from 'grommet';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import Loading from '../../Loading/Loading';
import setLanguage from './Language';
import Warning from '../../Warning/Warning';
import ChangeName from './ChangeName/ChangeName';
import ChangeEmail from './ChangeEmail/ChangeEmail';
import ChangePassword from './ChangePassword/ChangePassword';
import ChangeAdditionalInfo from './ChangeAdditionalInfo/ChangeAdditionalInfo';
import ChangeProfile from './ChangeProfile/ChangeProfile';
import ChangePicture from './ChangePicture/ChangePicture';
const ManageAccount = withTranslation()((props) => {
    const { t } = props;
    const { id = '0' } = useParams();
    setLanguage();
    const [active, setActive] = useState(0);
    const [loading, setLoading] = useState(true);
    const setInitialIndex = () => {
        const activeIndex = parseInt(id, 10);
        if (Number.isNaN(activeIndex)) {
            setActive(0);
            setLoading(false);
            return;
        }
        if (activeIndex < 0) {
            setActive(0);
            setLoading(false);
            return;
        }
        if (activeIndex > 5) {
            setActive(5);
            setLoading(false);
            return;
        }
        setActive(activeIndex);
        setLoading(false);
    };
    useEffect(() => {
        setInitialIndex();
    }, []);
    return (React.createElement(PageWrapper, null,
        React.createElement(PageTitle, { text: t('ManageAccount:Title') }),
        (loading) ? (React.createElement(Loading, null)) : (React.createElement(Tabs, { activeIndex: active, onActive: (nextIndex) => { setActive(nextIndex); } },
            React.createElement(Tab, { title: t('ManageAccount:Name') },
                React.createElement(Box, { border: "all", margin: {
                        horizontal: 'small',
                        vertical: 'none',
                    }, pad: "medium", align: "center" },
                    React.createElement(Box, { width: "large", background: "background-back", gap: "small" },
                        React.createElement(Warning, { status: "status-warning", text: t('ManageAccount:Warning.Text') }),
                        React.createElement(Box, { pad: "medium" },
                            React.createElement(ChangeName, null))))),
            React.createElement(Tab, { title: "Email" },
                React.createElement(Box, { border: "all", margin: {
                        horizontal: 'small',
                        vertical: 'none',
                    }, pad: "medium", align: "center" },
                    React.createElement(Box, { width: "large", background: "background-back", gap: "small" },
                        React.createElement(Warning, { status: "status-warning", text: t('ManageAccount:Warning.Text') }),
                        React.createElement(Box, { pad: "medium" },
                            React.createElement(ChangeEmail, null))))),
            React.createElement(Tab, { title: t('ManageAccount:Password') },
                React.createElement(Box, { border: "all", margin: {
                        horizontal: 'small',
                        vertical: 'none',
                    }, pad: "medium", align: "center" },
                    React.createElement(Box, { width: "large", background: "background-back", gap: "small" },
                        React.createElement(Warning, { status: "status-warning", text: t('ManageAccount:Warning.Text') }),
                        React.createElement(Box, { pad: "medium" },
                            React.createElement(ChangePassword, null))))),
            React.createElement(Tab, { title: t('ManageAccount:Additional') },
                React.createElement(Box, { border: "all", margin: {
                        horizontal: 'small',
                        vertical: 'none',
                    }, pad: "medium", align: "center" },
                    React.createElement(Box, { width: "large", background: "background-back", gap: "small" },
                        React.createElement(Box, { pad: "medium" },
                            React.createElement(ChangeAdditionalInfo, null))))),
            React.createElement(Tab, { title: t('ManageAccount:Image') },
                React.createElement(Box, { border: "all", margin: {
                        horizontal: 'small',
                        vertical: 'none',
                    }, pad: "medium", align: "center" },
                    React.createElement(Box, { width: "large", background: "background-back", gap: "small" },
                        React.createElement(Box, { pad: "medium" },
                            React.createElement(ChangePicture, null))))),
            React.createElement(Tab, { title: t('ManageAccount:Profile') },
                React.createElement(Box, { border: "all", margin: {
                        horizontal: 'small',
                        vertical: 'none',
                    }, pad: "medium", align: "center" },
                    React.createElement(Box, { width: "large", background: "background-back", gap: "small" },
                        React.createElement(Box, { pad: "medium" },
                            React.createElement(ChangeProfile, null)))))))));
});
ManageAccount.displayName = 'ManageAccount';
export default ManageAccount;
