import Axios from '../../../Utils/axios';
const onSubmit = async (values) => {
    let signin = {};
    await Axios().post('Identity/SignIn', values)
        .then((response) => {
        const { data } = response;
        signin = data;
        return signin;
    })
        .catch(() => {
        signin.Success = false;
        return signin;
    });
    return signin;
};
export default onSubmit;
