import { Anchor, Box, DropButton, Nav, } from 'grommet';
import { Logout, UserAdmin, UserSettings } from 'grommet-icons';
import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStateValue } from '../../AppContext/StateProvider';
import signout from './signout';
const IdentityManagerLinks = withTranslation()((props) => {
    const { t } = props;
    const [, dispatch] = useStateValue();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const onNavigate = (url) => {
        history.push(url);
        setOpen(false);
    };
    const onSignOut = async () => {
        await signout()
            .then((data) => {
            if (data.Success) {
                toast.success('Your connection was closed!');
                setOpen(false);
                dispatch({
                    type: 'changeFirstName',
                    value: '',
                });
                dispatch({
                    type: 'changeLastName',
                    value: '',
                });
                dispatch({
                    type: 'changeEmail',
                    value: '',
                });
                dispatch({
                    type: 'changeAuth',
                    value: false,
                });
                dispatch({
                    type: 'changeDrawer',
                    value: false,
                });
                dispatch({
                    type: 'changeValidated',
                    value: false,
                });
            }
            else {
                toast.error('Sorry, but it was not possible to close your connection');
            }
        })
            .catch(() => {
            toast.error('Sorry, but it was not possible to close your connection!');
        });
    };
    return (React.createElement(DropButton, { dropAlign: { top: 'bottom', right: 'right' }, icon: React.createElement(UserSettings, null), title: t('Header:Login.Title'), open: open, onClick: () => { setOpen(!open); }, dropContent: (React.createElement(Box, { gap: "small", justify: "center", pad: "none" },
            React.createElement(Nav, { pad: "small", background: "brandBlue" },
                React.createElement(Anchor, { label: t('Header:DropButton.Manage.Text'), title: t('Header:DropButton.Manage.Title'), icon: React.createElement(UserAdmin, { style: { width: '20px' } }), color: "white", onClick: () => { onNavigate('/manageAccount/0'); } }),
                React.createElement(Anchor, { label: t('Header:DropButton.Logout.Text'), title: t('Header:DropButton.Logout.Title'), icon: React.createElement(Logout, { style: { width: '20px' } }), onClick: onSignOut, color: "white" })))) }));
});
IdentityManagerLinks.displayName = 'IdentityManagerLinks';
export default IdentityManagerLinks;
