const ChangeAdditionalInfoPTBR = {
    Title: 'Alterar informações adicionais',
    Country: {
        Text: 'País',
        Title: 'Por favor informe teu pais',
    },
    State: {
        Text: 'Estado',
        Title: 'Por favor informe teu estado',
    },
    Phone: {
        Text: 'Telefone',
        Title: 'Por favor informe teu telefone',
    },
    Submit: {
        Text: 'Salvar',
        Title: 'Clique aqui para salvar',
    },
    Feedback: {
        Success: 'Informações atualizadas com sucesso',
        Failure: 'Desculpe, mas houve um problema ao tentar atualizar tuas informações',
    },
};
export default ChangeAdditionalInfoPTBR;
