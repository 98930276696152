import { Anchor, Box, Button, Paragraph, } from 'grommet';
import { Close } from 'grommet-icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStateValue } from '../../../AppContext/StateProvider';
import setLanguage from './Language';
const CookiesConsentContent = withTranslation()((props) => {
    const { t, CookieString } = props;
    const [, dispatch] = useStateValue();
    const history = useHistory();
    setLanguage();
    const navigateToPrivacy = () => { history.push('/privacy'); };
    const closeConsent = () => {
        document.cookie = CookieString;
        dispatch({
            type: 'changeConsent',
            value: { ShowBanner: false, CookieString },
        });
    };
    return (React.createElement(Box, { fill: "horizontal", pad: "medium", direction: "row", background: "background-contrast", border: {
            size: 'medium',
            side: 'top',
        } },
        React.createElement(Box, { flex: true },
            React.createElement(Paragraph, { fill: true },
                t('CookiesConsent:Title'),
                "\u00A0",
                React.createElement(Anchor, { title: t('CookiesConsent:Link.Title'), onClick: navigateToPrivacy }, t('CookiesConsent:Link.Text')))),
        React.createElement(Box, { pad: "small", justify: "center" },
            React.createElement(Button, { primary: true, icon: (React.createElement(Close, { style: {
                        paddingLeft: 10,
                    } })), label: t('CookiesConsent:Button.Text'), title: t('CookiesConsent:Button.Title'), reverse: true, onClick: closeConsent }))));
});
CookiesConsentContent.displayName = 'CookiesConsentContent';
export default CookiesConsentContent;
