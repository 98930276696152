import { Anchor, Box, Button, FormField, TextInput, } from 'grommet';
import { Send } from 'grommet-icons';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormWrapper from '../../FormWrapper/FormWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import PageWrapper from '../../PageWrapper/PageWrapper';
import onPasswordRecoverySubmit from './onPasswordRecoverySubmit';
const PasswordRecovery = withTranslation()((props) => {
    // TODO: Add translation
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const history = useHistory();
    const [values, setValues] = useState({ Email: '' });
    const [submitting, setSubmitting] = useState(false);
    const onChange = (value) => {
        setValues(value);
    };
    const submit = async (event) => {
        const { value } = event;
        setSubmitting(true);
        await onPasswordRecoverySubmit(value)
            .then((data) => {
            if (data.Success) {
                toast.success('New password generated! You will receive it in your email');
            }
            else {
                toast.error('Oops! Something happened on the submission. Please, review the data and send it again.');
            }
            setSubmitting(false);
        })
            .catch(() => {
            toast.error('Oops! Something happened on the submission. Please, review the data and send it again.');
        });
    };
    return (React.createElement(PageWrapper, null,
        React.createElement(PageTitle, { text: "Receive a new password" }),
        React.createElement(Box, { fill: "horizontal", align: "center" },
            React.createElement(Box, { width: "large", border: "bottom", pad: {
                    vertical: 'small',
                }, margin: {
                    vertical: 'small',
                } },
                React.createElement(FormWrapper, { values: values, onChange: onChange, onSubmit: submit },
                    React.createElement(FormField, { label: "Email", name: "Email", width: "large", required: true, validate: {
                            // eslint-disable-next-line max-len
                            regexp: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
                            message: 'Invalid email',
                        } },
                        React.createElement(TextInput, { name: "Email", type: "email", "aria-label": "Email", title: "Email", placeholder: "email@example.com" })),
                    React.createElement(Box, { direction: "row", justify: "between", margin: { top: 'medium' } },
                        React.createElement(Button, { icon: React.createElement(Send, null), type: "submit", label: "Receive a new password", title: "Receive a new password", primary: true, reverse: true, disabled: submitting })))),
            React.createElement(Box, { justify: "center", align: "center", fill: "horizontal", pad: "small" },
                React.createElement(Anchor, { size: "large", label: "Or go back to identity page", title: "Or go back to identity page", onClick: () => { history.push('/identity'); } })))));
});
PasswordRecovery.displayName = 'PasswordRecovery';
export default PasswordRecovery;
