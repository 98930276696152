import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import toTop from '../../Utils/toTop';
import NotFound from '../404/NotFound';
import Home from '../Home/Home';
import Identity from '../Identity/Identity';
import ManageAccount from '../Identity/ManageAccount/ManageAccount';
import PasswordRecovery from '../Identity/PasswordRecovery/PasswordRecovery';
// const HeroDetail = loadable(() => import('../../HeroDetail'));
const MainRouter = () => {
    const location = useLocation();
    useEffect(() => {
        toTop();
    }, [location]);
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/", component: Home }),
        React.createElement(Route, { exact: true, path: "/identity", component: Identity }),
        React.createElement(Route, { exact: true, path: "/404", component: NotFound }),
        React.createElement(Route, { exact: true, path: "/ManageAccount/:id?", component: ManageAccount }),
        React.createElement(Route, { exact: true, path: "/passwordRecovery", component: PasswordRecovery }),
        React.createElement(Route, { component: NotFound })));
};
MainRouter.displayName = 'MainRouter';
export default MainRouter;
