import { Box, Button, Paragraph } from 'grommet';
import { Facebook, Twitter, Youtube } from 'grommet-icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
const Footer = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    return (React.createElement(Box, { fill: true, justify: "center", align: "center" },
        React.createElement(Box, { background: "brandBlue", fill: "horizontal", pad: "xsmall", justify: "center", align: "center", direction: "row" },
            React.createElement(Button, { title: "Facebook", icon: React.createElement(Facebook, null) }),
            React.createElement(Button, { title: "Twitter", icon: React.createElement(Twitter, null) }),
            React.createElement(Button, { title: "YouTube", icon: React.createElement(Youtube, null) })),
        React.createElement(Box, { flex: "grow", background: "brand", fill: "horizontal", justify: "center", align: "center" },
            React.createElement(Paragraph, { fill: true, margin: "none" }, "Copyright \u00A9 2018 - Harmony With Nature"))));
});
Footer.displayName = 'Footer';
export default Footer;
