const ChangeAdditionalInfoSpa = {
    Title: 'Cambia tu información adicional',
    Country: {
        Text: 'País',
        Title: 'Por favor informe a su país',
    },
    State: {
        Text: 'Estado',
        Title: 'Por favor informe a su estado',
    },
    Phone: {
        Text: 'Teléfono',
        Title: 'Por favor informe a su teléfono',
    },
    Submit: {
        Text: 'Salvar',
        Title: 'Haga clic aquí para guardar los datos',
    },
    Feedback: {
        Success: 'Datos enviados con éxito',
        Failure: '¡UPS! Algo sucedió en la presentación. Por favor, revise los datos y envíelos nuevamente.',
    },
};
export default ChangeAdditionalInfoSpa;
